export enum EnumWidgetId {
    AUSTRALIAN_COMPANY_NUMBER_GENERATOR = "1",
    AUSTRALIAN_COMPANY_NUMBER_VALIDATOR = "2",
    AUSTRALIAN_TAX_FILE_NUMBER_GENERATOR = "3",
    AUSTRALIAN_TAX_FILE_NUMBER_VALIDATOR = "4",
    NEW_ZEALAND_IRD_GENERATOR = "5",
    NEW_ZEALAND_IRD_VALIDATOR = "6",
    AUSTRALIAN_BUSINESS_NUMBER_GENERATOR = "7",
    AUSTRALIAN_BUSINESS_NUMBER_VALIDATOR = "8",
    AUSTRALIAN_MEDICARE_NUMBER_GENERATOR = "9",
    AUSTRALIAN_MEDICARE_NUMBER_VALIDATOR = "10",
}