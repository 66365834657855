import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { WidgetExecuteBatchRequest, WidgetExecuteBatchResponseItem } from '../../@types/WidgetExecuteBatch';
import { RootState } from '../../store/root';
import { WidgetRequestsExecuteBatch } from './widgetExecutionsApi';

export interface WidgetExecutionsState {
    byId: {
        [id: string]: WidgetExecuteBatchResponseItem;
    }
}

const initialState: WidgetExecutionsState = {
    byId: {},
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const executeBatchAsync = createAsyncThunk(
    'counter/fetchCount',
    async (request: WidgetExecuteBatchRequest) => {
        const response = await WidgetRequestsExecuteBatch(request);
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const widgetExecutionsSlice = createSlice({
    name: 'widgetExecutions',
    initialState,
    reducers: {
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload;
        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(executeBatchAsync.fulfilled, (state: WidgetExecutionsState, action) => {
                let test: { [id: string]: WidgetExecuteBatchResponseItem} = action.payload.responseItems.reduce((acc: {[index: string]: WidgetExecuteBatchResponseItem}, item: WidgetExecuteBatchResponseItem) => {
                    acc[item.itemId] = item;
                    return acc;
                }, {});

                state.byId = {...state.byId, ...test};
            })
            .addCase(executeBatchAsync.rejected, (state) => {
                throw new Error("Need to handle this")
            });
    },
});

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;
export const selectResponseItemsByBatchId = (state: RootState, batchId: string) => {
    return Object.values(state.widgetExecutions.byId)
        .filter(item => item.batchId === batchId)
}

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//     dispatch,
//     getState
// ) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

export default widgetExecutionsSlice.reducer;